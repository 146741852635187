import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { useState, useRef } from 'react';
import { useClickOutside } from './';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useclickoutside"
    }}>{`useClickOutside`}</h1>
    <p><inlineCode parentName="p">{`useClickOutside`}</inlineCode>{` should be used to listen for clicks outside of given element.
It is useful when you, for example, want to listen for clicks outside of a modal
or similar.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useClickOutside } from '@fransvilhelm/hooks';

const Example = () => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setOpen(false));
  return <div>{open && <Modal ref={modalRef} />}</div>;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'() => {\n  const [open, setOpen] = useState(false)\n  const ref = useRef(null)\n  useClickOutside(ref, () => setOpen(false))\n  return (\n    <div>\n      <button onClick={() => setOpen(true)}>Open</button>\n      {open && <p ref={ref}>Hey I\'m open</p>}\n      <p>You can click here to close the above paragraph</p>\n      <p>Or here...</p>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      useState,
      useRef,
      useClickOutside,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [open, setOpen] = useState(false);
        const ref = useRef(null);
        useClickOutside(ref, () => setOpen(false));
        return <div>
        <button onClick={() => setOpen(true)}>Open</button>
        {open && <p ref={ref}>Hey I'm open</p>}
        <p>You can click here to close the above paragraph</p>
        <p>Or here...</p>
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useClickOutside`}</inlineCode>{` two parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ref to element on which to listen for clicks outside`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`handler`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(event: Event) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Event handler fired on clicks outside of element`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      